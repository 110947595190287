
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from "react-router-dom";
import Turnstile, { useTurnstile } from "react-turnstile";


export default function Registration() {
  return (<div className="row">
    <div className="col-lg-4"></div>
    <div className="col-lg-4"><RegistrationForm /></div>
    <div className="col-lg-4"></div>
  </div>);
}

function RegistrationForm() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [formError, setFormError] = useState('');
  const [formSuccess, setFormSuccess] = useState('');
  const navigate = useNavigate();
  const onSubmit = (data, e) => { registerApi(data, e, setFormError, setFormSuccess, navigate) }


  return (<form onSubmit={handleSubmit(onSubmit)}>


    {formSuccess ? (
      <div className="alert alert-dismissible alert-success">
        {formSuccess}
      </div>
    ) : ""}

    {formError ? (
      <div className="alert alert-dismissible alert-danger">
        {formError}
      </div>
    ) : ""}
    <fieldset>
      <div className="form-group">
        <label htmlFor="exampleInputEmail1" className="form-label mt-4">Email address</label>
        <input type="email" name="email" className={errors.email ? "form-control is-invalid" : "form-control"} id="exampleInputEmail1" aria-describedby="emailHelp" aria-invalid={errors.email ? "true" : "false"} placeholder="Enter email" {...register("email", { required: true, pattern: /^\S+@\S+$/i })} />
        {errors.email?.type === 'required' && <p className='text-danger'>Enter a valid email</p>}
        <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
      </div>
      <div className="form-group">
        <label htmlFor="exampleInputPassword1" className="form-label mt-4">Password</label>
        <input type="password" name="password" className="form-control" id="exampleInputPassword1" placeholder="Password" aria-invalid={errors.password ? "true" : "false"} {...register("password", { required: true, minLength: 6, maxLength: 32 })} />
        {errors.password?.type === 'required' && <p className='text-danger'>Password should be between 6 and 32 characters</p>}
      </div>
      <div className="form-group">
        <label className="col-form-label mt-4" htmlFor="inputDefault">Full Service Url</label>
        <input type="url" name="url" className="form-control" placeholder="https://example.com" id="inputDefault" aria-invalid={errors.url ? "true" : "false"} {...register("url", { required: true, pattern: '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?' })} />
        {errors.url?.type === 'required' && <p className='text-danger'>Enter a complete valid url</p>}
      </div>

      <div className="form-group">
        <TurnstileWidget />
      </div>
      <button type="submit" className="btn btn-primary">Register</button>
    </fieldset>
  </form>
  );
}

function registerApi(data, e, formErrorCallback, successCallback, navigateCallback) {
  e.preventDefault();
  const formData = new FormData(e.target);
  fetch("/api/users/register", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: formData.get('email'),
      password: formData.get('password'),
      url: formData.get('url'),
    })
  }).then((response) => response.json())
    .then((data) => {
      if (data.error) {
        formErrorCallback(data.error)
      } else {
        successCallback("Registration complete, check your inbox for verification email")
        setTimeout(() => {
          window.location.href = "/";
        }, 10000);
      }
    });
}

function TurnstileWidget() {
  const turnstile = useTurnstile();
  return (
    <Turnstile
      sitekey="0x4AAAAAAA0VivfXqmUGFnvU"
    />
  );
}
